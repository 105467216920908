import styles from '../styles/components/TeamMember.module.sass';
import Link from 'next/link';
import React from 'react';
import Z_Strapi_Image from "./Z_Strapi_Image";

const TeamMember = ({
                        team_member,
                    }) => {

    return (
        <div className={styles.team_member}>
            <div className={styles.image}>
                <Z_Strapi_Image
                    image={team_member.image}
                    fill={true}
                    sizes={'(max-width: 768px) 100vw,(max-width: 1200px) 50vw,25vw'}
                />
            </div>

            <div className={styles.content}>
                <span className={styles.name}>{`${team_member.firstname} ${team_member.lastname}`}</span>
                <span className={styles.job}>{team_member.job}</span>
                <span className={styles.function}>{team_member.function}</span>
                <Link
                    scroll={false}
                    href={`mailto:${team_member.mail}`}
                    className={styles.mail}
                >{team_member.mail}</Link>
            </div>
        </div>
    );
};

export default TeamMember;
