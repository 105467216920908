import {forwardRef} from "react";

const Banner_Image_Line_SVG = ({...props}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
		     width="636.408"
		     height="631.666"
		     viewBox="0 0 636.408 631.666"

		     {...props}
		>
			<path id="Path_124"
			      data-name="Path 124"
			      d="M-19375-8148.1c-50.5-2.4-118.217,11.55-195.514,68.468-76.531,56.354-112.307,159.527-112.307,240.914,0,163.428,132.672,301.025,307.82,301.025s304.479-108.377,307.545-301.025-164.7-339.08-331.213-265.115"
			      transform="translate(19693.32 8158.859)"
			      fill="none"
			      stroke="#d36f3b"
			      strokeWidth="21"
			/>
		</svg>
	)
}

export default Banner_Image_Line_SVG
