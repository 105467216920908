import styles                                        from '../styles/components/WhyChoseUsItem.module.sass'
import CollaspedItem_Arrow_SVG                       from './svg/icons/CollaspedItem_Arrow_SVG'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import cn                                            from 'classnames'
import Markdown                                      from 'marked-react'
import gsap                                          from 'gsap/dist/gsap'

const WhyChoseUsItem = ({
	                        id,
	                        question,
	                        answer,
	                        opened,
	                        setOpened,
                        }) => {

	const bottomRef = useRef()
	const timeline  = useRef()

	useEffect(
		() => {
			timeline.current = gsap.timeline({
				                                 defaults: {
					                                 duration: .3,
				                                 },
			                                 })
			                       .to(
				                       `.why_chose_us_item_${id} .answer`,
				                       {
					                       visibility: 'visible',
				                       },
				                       '<',
			                       )
			                       .fromTo(
				                       `.why_chose_us_item_${id} .bottom`,
				                       {
					                       height: 0,
				                       },
				                       {
					                       height: 'auto',
				                       },
				                       '<',
			                       )
			                       .pause()

			return () => {
				timeline.current?.kill()
			}
		},
		[
			id,
		],
	)

	useEffect(
		() => {
			if (opened) {
				console.log(timeline.current)
				timeline.current?.play()
			} else {
				timeline.current?.reverse()
			}
		},
		[
			opened,
			timeline,
		],
	)

	return (
		<div
			className={cn(
				styles.why_chose_us_item,
				`why_chose_us_item_${id}`,
				{[styles.opened]: opened},
			)}
		>
			<div
				className={styles.top}
				//region Handlers
				onClick={() => {
					setOpened(id)
				}}
				//endregion
			>
				<span className={styles.question}>{question}</span>

				<div className={styles.icon_container}>
					<CollaspedItem_Arrow_SVG className={styles.icon}/>
				</div>
			</div>

			<div
				ref={bottomRef}
				className={cn(
					styles.bottom,
					'bottom',
				)}
			>
				<div
					className={cn(
						styles.answer,
						'answer',
					)}
				><Markdown value={answer}/></div>
			</div>
		</div>
	)
}

export default WhyChoseUsItem
