const Swiper_Arrow_SVG = ({props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='55'
			height='55'
			viewBox='0 0 55 55'

			{...props}
		>
			<g
				id='Group_337'
				data-name='Group 337'
				transform='translate(-40 774) rotate(-90)'
			>
				<path
					id='Path_4250'
					data-name='Path 4250'
					d='M27.5,0A27.5,27.5,0,1,1,0,27.5,27.5,27.5,0,0,1,27.5,0Z'
					transform='translate(719 40)'
					fill='#2d3243'
					opacity='0.102'
				/>
				<g
					id='Path_4249'
					data-name='Path 4249'
					transform='translate(719 40)'
					fill='none'
				>
					<path
						d='M27.5,0A27.5,27.5,0,1,1,0,27.5,27.5,27.5,0,0,1,27.5,0Z'
						stroke='none'
					/>
					<path
						d='M 27.5 2 C 24.056640625 2 20.71733856201172 2.673938751220703 17.57485961914062 4.003101348876953 C 14.53858184814453 5.287330627441406 11.81129837036133 7.126258850097656 9.468780517578125 9.468780517578125 C 7.126258850097656 11.81129837036133 5.287330627441406 14.53858184814453 4.003101348876953 17.57485961914062 C 2.673938751220703 20.71733856201172 2 24.056640625 2 27.5 C 2 30.943359375 2.673938751220703 34.28266143798828 4.003101348876953 37.42514038085938 C 5.287330627441406 40.46141815185547 7.126258850097656 43.18869781494141 9.468780517578125 45.53121948242188 C 11.81129837036133 47.87374114990234 14.53858184814453 49.71266937255859 17.57485961914062 50.99689865112305 C 20.71733856201172 52.3260612487793 24.056640625 53 27.5 53 C 30.943359375 53 34.28266143798828 52.3260612487793 37.42514038085938 50.99689865112305 C 40.46141815185547 49.71266937255859 43.18869781494141 47.87374114990234 45.53121948242188 45.53121948242188 C 47.87374114990234 43.18869781494141 49.71266937255859 40.46141815185547 50.99689865112305 37.42514038085938 C 52.3260612487793 34.28266143798828 53 30.943359375 53 27.5 C 53 24.056640625 52.3260612487793 20.71733856201172 50.99689865112305 17.57485961914062 C 49.71266937255859 14.53858184814453 47.87374114990234 11.81129837036133 45.53121948242188 9.468780517578125 C 43.18869781494141 7.126258850097656 40.46141815185547 5.287330627441406 37.42514038085938 4.003101348876953 C 34.28266143798828 2.673938751220703 30.943359375 2 27.5 2 M 27.5 0 C 42.68782806396484 0 55 12.31216812133789 55 27.5 C 55 42.68782806396484 42.68782806396484 55 27.5 55 C 12.31216812133789 55 0 42.68782806396484 0 27.5 C 0 12.31216812133789 12.31216812133789 0 27.5 0 Z'
						stroke='none'
						fill='#ededed'
					/>
				</g>
				<g
					id='Group_338'
					data-name='Group 338'
				>
					<line
						id='Line_9'
						data-name='Line 9'
						y1='27.672'
						transform='translate(746.5 80.336) rotate(180)'
						fill='none'
						stroke='#ededed'
						strokeMiterlimit='10'
						strokeWidth='2'
					/>
					<path
						id='Path_141'
						data-name='Path 141'
						d='M0,10.063,10.063,0,20.125,10.063'
						transform='translate(756.563 80.336) rotate(180)'
						fill='none'
						stroke='#ededed'
						strokeMiterlimit='10'
						strokeWidth='2'
					/>
				</g>
			</g>
		</svg>
	)
}

export default Swiper_Arrow_SVG
