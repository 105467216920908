const Income_Birth_Line_SVG = ({...props}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
		     width="3192.314"
		     height="450.341"
		     viewBox="0 0 3192.314 450.341"

		     {...props}
		>
			<g id="Group_333"
			   data-name="Group 333"
			   transform="translate(24230 2727.236)"
			>
				<rect id="BG_btn"
				      width="45"
				      height="45"
				      rx="22.5"
				      transform="translate(-24230 -2510.704)"
				      fill="#d36f3b"
				/>
				<path id="Path_4249"
				      data-name="Path 4249"
				      d="M489.41,1932.411s291.016,16.68,468.27-47.252,253.113-228.295,561.924-156.8,323.566,662.347,1098.287,156.8,679.8,163.25,1035.68,248.356"
				      transform="translate(-24693 -4417.704)"
				      fill="none"
				      stroke="#d36f3b"
				      strokeWidth="15"
				/>
			</g>
		</svg>
	)
}

export default Income_Birth_Line_SVG
