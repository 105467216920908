const CollapsedItem_Arrow_SVG = ({
	                                 middleDotClassName,
	                                 ...props
                                 }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='11.683'
			height='15.601'
			viewBox='0 0 11.683 15.601'

			stroke='#3b4156'

			{...props}
		>
			<g
				id='Group_101'
				data-name='Group 101'
				transform='translate(18.843 29.92) rotate(180)'
			>
				<line
					id='Line_9'
					data-name='Line 9'
					y1='14.363'
					transform='translate(13.001 15.557)'
					fill='none'
					strokeMiterlimit='10'
					strokeWidth='1.75'
				/>
				<path
					id='Path_141'
					data-name='Path 141'
					d='M7.778,20.78,13,15.557l5.223,5.223'
					transform='translate(0 0)'
					fill='none'
					strokeMiterlimit='10'
					strokeWidth='1.75'
				/>
			</g>
		</svg>
	)
}

export default CollapsedItem_Arrow_SVG
