import axios from "axios";

export enum SendingState {
	NothingToSend,
	Sending,
	Sent,
	Error,
}

export default function getStrapiImage(data: any, size: string | null = null) {
	if (data) {
		if (size) {
			return data.formats[size] ?? data
		} else {
			return data
		}
	}
	return null
}
