const Logo_Background_SVG = ({...props}) => {
	return (
			<svg width="1515" height="1043" viewBox="0 0 1515 1043" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
				<g clipPath="url(#clip0_504_8)">
					<g opacity="0.03">
						<path d="M710.907 546.721C468.007 546.721 468.007 423.931 468.007 384.668C468.007 372.63 468.007 217.984 710.907 217.984C948.616 217.984 974.174 490.419 974.174 490.419C974.174 490.419 859.718 546.719 710.907 546.719M1300.78 45.19C1300.78 280.769 1177.53 370.038 1177.53 370.038C1177.53 370.038 1072.42 0 710.907 0C373.835 0 249.841 191.778 249.841 384.668C249.841 433.006 268.917 760.817 690.441 760.817C756.741 760.817 925.835 734.61 948.616 723.591C955.098 733.777 886.665 976.95 617.471 976.95C353.74 976.95 248.915 764.15 237.71 572.002H0C9.538 885.274 199 1195.12 605.9 1195.12C978.346 1195.12 1215.59 976.95 1215.59 642.75C1531.27 481.437 1513.86 45.1 1513.86 45.1L1300.78 45.19Z" fill="#BAC3E3"/>
					</g>
				</g>1
				<defs>
					<clipPath id="clip0_504_8">
						<rect width="1514.03" height="1195.12" fill="white"/>
					</clipPath>
				</defs>
			</svg>
	)
}

export default Logo_Background_SVG