import Link   from 'next/link'
import styles from '../styles/components/LinkWithArrow.module.sass'
import React  from 'react'

const LinkWithArrow = ({
	                       link,
	                       text,
                       }) => {
	return (
		<Link
			scroll={false}
			href={link}
			className={styles.link}
		>
			<span className={styles.text}>{text}</span>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24.917'
				height='11.683'
				viewBox='0 0 24.917 11.683'

				className={styles.arrow}
			>
				<g
					id='Group_105'
					data-name='Group 105'
					transform='translate(39.236 -7.159) rotate(90)'
				>
					<line
						id='Line_9'
						data-name='Line 9'
						y1='23.68'
						transform='translate(13.001 15.557)'
						fill='none'
						stroke='#d36f3b'
						strokeMiterlimit='10'
						strokeWidth='1.75'
					/>
					<path
						id='Path_141'
						data-name='Path 141'
						d='M7.778,20.78,13,15.557l5.223,5.223'
						transform='translate(0 0)'
						fill='none'
						stroke='#d36f3b'
						strokeMiterlimit='10'
						strokeWidth='1.75'
					/>
				</g>
			</svg>
		</Link>
	)
}

export default LinkWithArrow
