import Header                                        from '../components/Header'
import styles                                        from '../styles/MainPage.module.sass'
import Link                                          from 'next/link'
import SocialNetwork_WithLine                        from '../components/SocialNetwork_WithLine'
import Image                                         from 'next/image'
import Banner_Image_Line_SVG                         from '../components/svg/lines/Banner_Image_Line_SVG'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import gsap, {Power0}                                from 'gsap/dist/gsap'
import {ScrollTrigger}                               from 'gsap/dist/ScrollTrigger'
import {DrawSVGPlugin}                               from 'gsap/dist/DrawSVGPlugin'
import Logo_SVG                                      from '../components/svg/Logo_SVG'
import Arrow_Right_SVG                               from '../components/svg/icons/Arrow_Right_SVG'
import Income_Birth_Line_SVG                         from '../components/svg/lines/Income_Birth_Line_SVG'
import cn                                            from 'classnames'
import TopLeftIMG                                    from '/public/images/income_birth/top_left.png'
import BottomLeftIMG                                 from '/public/images/income_birth/bottom_left.png'
import BottomIMG                                     from '/public/images/income_birth/bottom.png'
import TopRightIMG                                   from '/public/images/income_birth/top_right.png'
import RightIMG                                      from '/public/images/income_birth/right.png'
import NewsletterImage                               from '/public/images/newsletter.jpg'
import {Swiper, SwiperSlide}                         from 'swiper/react'
import SwiperCore, {FreeMode, Navigation}            from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import LinkWithArrow                                 from '../components/LinkWithArrow'
import WhyChoseUsItem                                from '../components/whyChoseUsItem'
import NewsletterForm                                from '../components/NewsletterForm'
import ContactUs_Section                             from '../components/ContactUs_Section'
import Head                                          from 'next/head'
import axios                                         from 'axios'
import qs                                            from 'qs'
import {addScrollSmoother, getFooterData}            from './_app'
import TeamMember                                    from '../components/teamMember'
import Markdown                                      from 'marked-react'
import Z_Strapi_Image                                from '../components/Z_Strapi_Image'
import {FontAwesomeIcon}                             from '@fortawesome/react-fontawesome'
import Footer                                        from '../components/Footer'
import Parallax                                      from 'parallax-js'
import Swiper_Arrow_SVG                              from '../components/svg/icons/Swiper_Arrow_SVG'
import Logo_Background_SVG from "../components/svg/Logo_Background_SVG";
import Logo_Birth_Mobile_SVG from "../components/svg/Logo_Birth_Mobile_SVG";
import Logo_Birth_SVG from "../components/svg/Logo_Birth_SVG";

if (typeof window
    !== 'undefined') {
	gsap.registerPlugin(ScrollTrigger)
	gsap.registerPlugin(DrawSVGPlugin)

	SwiperCore.use([FreeMode])
}

const MainPage = ({
	                  data,
	                  firstLoad,
                  }) => {

	const parallaxSceneRef = useRef()

	//region Small screen size
	const [smallScreenSize, setSmallScreenSize] = useState(false)

	const changeWindowSize = () => {
		let windowSize = window.innerWidth
		setSmallScreenSize(windowSize
		                   <= 1024)
	}

	useEffect(
		() => {
			changeWindowSize()
			window.addEventListener(
				'resize',
				() => {
					changeWindowSize()
				},
			)
		},
		[],
	)
	//endregion

	//region States
	const [selectedWhyChoseUs, setSelectedWhyChoseUs] = useState(null)
	const [randomTeamMembers, setRandomTeamMembers]   = useState([])
	useEffect(
		() => {
			// Add partners
			const partners_id = [
				1,
				22,
				32,
			]
			// Get partners
			const partners    = data.team_members.filter(tm => partners_id.includes(tm.id))

			// Get others
			const others = data.team_members.filter(tm => !partners_id.includes(tm.id))

			// Sort others randomly
			const randomOthers = others
				.sort(() => 0.5
				            - Math.random())
				.slice(
					0,
					7,
				)

			setRandomTeamMembers(partners.concat(randomOthers))
		},
		[data.team_members],
	)
	//endregion

	//region Handlers
	//region Scroll smoother
	const [scroller, setScroller] = useState(false)
	useEffect(
		() => {
			return addScrollSmoother(setScroller)
		},
		[],
	)
	//endregion

	let pinIncomeBirth         = useMemo(
		() => {
			return null
		},
		[],
	)
	const createPinIncomeBirth = () => {
		// pinIncomeBirth?.kill()
		// pinIncomeBirth = null

		const mm = gsap.matchMedia()
		mm.add(
			{
				isDesktop: '(min-width: 1025px)',
				isMobile:  '(max-width: 1024px)',
			},
			context => {
				let {
					    isDesktop,
					    isMobile,
				    } = context.conditions

				if (isDesktop) {
					pinIncomeBirth = gsap.timeline({
						                               scrollTrigger: {
							                               id:      'PinIncomeBirthScrollTrigger',
							                               markers: false,
							                               trigger: '.income_birth_container',
							                               start:   'top top',
							                               end:     'bottom bottom',

							                               pin:        '.income_birth_container .income_birth_content',
							                               pinSpacing: false,

							                               scrub: true,
						                               },
						                               defaults:      {
							                               duration: 1,
							                               ease:     Power0.easeNone,
						                               },
					                               })

					const windowWidth = window.innerWidth

					pinIncomeBirth
						.addLabel(
							'start',
							'<',
						)
						// Move line and titles
						.to(
							'.income_birth_content > .title',
							{
								x: windowWidth
								   * -1.5,
							},
							'<',
						)
						.to(
							'.income_birth_content #income_birth_line',
							{
								x: windowWidth
								   * -1.5,
							},
							'<',
						)
						.fromTo(
							'.income_birth_content #income_birth_line path',
							{drawSVG: '0'},
							{
								drawSVG: '100%',
							},
							'<',
						)
						.to(
							'.income_birth_content #income_birth_line path',
							{
								drawSVG:  '0%',
								duration: .08,
							},
							'>',
						)
						.to(
							'.income_birth_content .discover_us',
							{
								x: windowWidth
								   * -1.5,
							},
							'start',
						)

						// Move elements
						.to(
							'.income_birth_content .cegex_and_qubbe',
							{
								x:        0,
								duration: .19
								          / 2.75
								          * 5.4,
							},
							'<',
						)
						.to(
							'.income_birth_content .experience',
							{
								x:        0,
								duration: .26
								          / 2.75
								          * 5.4,
							},
							'<',
						)
						.to(
							'.income_birth_content .twenty_twenty_two',
							{
								x:        0,
								duration: .25
								          / 2.75
								          * 5.4,
							},
							'<',
						)
						.to(
							'.income_birth_content .service',
							{
								x:        0,
								duration: .4
								          / 2.75
								          * 5.4,
							},
							'<',
						)
						.to(
							'.income_birth_content .conseil',
							{
								x:        0,
								duration: .27
								          / 2.75
								          * 5.4,
							},
							'<',
						)
						.to(
							'.income_birth_content .audit',
							{
								x:        0,
								duration: .35
								          / 2.75
								          * 5.4,
							},
							'<',
						)
						.to(
							'.income_birth_content .social',
							{
								x:        0,
								duration: .38
								          / 2.75
								          * 5.4,
							},
							'<',
						)
						.to(
							'.income_birth_content .text',
							{
								x:        0,
								duration: .41
								          / 2.75
								          * 5.4,
							},
							'<',
						)
						.to(
							'.income_birth_content .icon_container',
							{
								x:        0,
								duration: .47
								          / 2.75
								          * 5.4,
							},
							'<',
						)
						// Move floating elements
						.fromTo(
							'.income_birth_content .floating_elements',
							{
								x: windowWidth
								   * 1.5,
							},
							{
								x:        0,
								duration: .6
								          * 1.8,
							},
							'<',
						)
				} else {
					pinIncomeBirth = gsap.timeline({
						                               scrollTrigger: {
							                               markers: false,
							                               trigger: '.income_birth_container',
							                               start:   'top top',
							                               end:     'bottom bottom',

							                               pin:        '.income_birth_container .center',
							                               pinSpacing: false,

							                               // Anticipate pin
							                               anticipatePin: 1,

							                               scrub: true,
						                               },
						                               defaults:      {
							                               duration: 1,
							                               ease:     Power0.easeNone,
						                               },
					                               })

					pinIncomeBirth
						.addLabel(
							'start',
							'<',
						)
						// Draw line
						.fromTo(
							'.income_birth_content #income_birth_line path',
							{drawSVG: '0'},
							{
								drawSVG: '100%',
							},
							'<',
						)
						.to(
							'.income_birth_content #income_birth_line path',
							{
								drawSVG:  '0%',
								duration: .08,
							},
							'>',
						)
						// Move elements
						.to(
							'.income_birth_content',
							{
								duration: 1,
							},
							'start',
						)
						.to(
							'.income_birth_content .cegex_and_qubbe',
							{
								y:        0,
								duration: .23,
							},
							'<',
						)
						.to(
							'.income_birth_content .experience',
							{
								y:        0,
								duration: .40,
							},
							'<',
						)
						.to(
							'.income_birth_content .twenty_twenty_two',
							{
								y:        0,
								duration: .34,
							},
							'<',
						)
						.to(
							'.income_birth_content .service',
							{
								y:        0,
								duration: .67,
							},
							'<',
						)
						.to(
							'.income_birth_content .conseil',
							{
								y:        0,
								duration: .79,
							},
							'<',
						)
						.to(
							'.income_birth_content .audit',
							{
								y:        0,
								duration: .51,
							},
							'<',
						)
						.to(
							'.income_birth_content .social',
							{
								y:        0,
								duration: .56,
							},
							'<',
						)
						.to(
							'.income_birth_content .links',
							{
								y:        0,
								duration: 1,
							},
							'<',
						)
				}
			},
		)
	}

	useEffect(
		() => {
			new Parallax(parallaxSceneRef.current)

			if (scroller) {
				createPinIncomeBirth()

				// Check if we need to scroll to 'income-birth' section
				const anchor = window.location.hash
				console.log(anchor)
				if (anchor
				    === '#income-birth') {
					if (typeof scroller
					    !== 'boolean') {
						setTimeout(
							() => {
								scroller?.scrollTo(
									'#income-birth',
									true,
									'top top',
								)
							},
							500,
						)
					} else {
						gsap.to(
							window,
							{
								scrollTo: '#income-birth',
								duration: .3,
								ease:     Power0.easeNone,
							},
						)
					}
				}

				return () => {
					pinIncomeBirth?.kill()
					pinIncomeBirth = null
				}
			}
		},
		[scroller],
	)

	const changeSelectedWhyChoseUs = id => {
		setSelectedWhyChoseUs(selectedWhyChoseUs
		                      === id
		                      ? null
		                      : id)
	}
	//endregion

	//region Team swiper
	const sliderRef = useRef(null)

	const [isBeginning, setIsBeginning] = useState(true)
	const [isEnd, setIsEnd]             = useState(false)

	const handlePrev = () => {
		sliderRef.current.swiper.slidePrev()
	}

	const handleNext = () => {
		sliderRef.current.swiper.slideNext()
	}
	//endregion

	return (
		<>
			<Head>
				<title>{data.seo_title}</title>
				<meta
					name={'description'}
					content={data.seo_description}
				/>

				{/*region SEO*/}
				{/*region OG*/}
				<meta
					property='og:title'
					content={data.seo_title}
				/>
				<meta
					property='og:description'
					content={data.seo_description}
				/>

				<meta
					property='og:url'
					content='https://www.income.ec'
				/>
				<link
					rel='canonical'
					href='https://www.income.ec'
				/>
				{/*endregion*/}
				{/*endregion*/}
			</Head>
			<div id='smooth-wrapper'>
				<div
					id='smooth-content'
					className={styles.page_content}
				>
					<Header firstLoad={firstLoad}/>

					{/*region Banner*/}
					<div className={styles.banner}>
						{/*region Logo Background*/}
						<Logo_Background_SVG className={styles.background_svg}/>
						{/*endregion*/}

						{/*region Left*/}
						<div className={styles.left}>
							{/*region Text*/}
							<div className={styles.text}>
								{/*region title*/}
								<h1 className={styles.title}><Markdown value={data.banner.title}/></h1>
								{/*endregion*/}

								{/*region Description*/}
								<span className={styles.description}><Markdown value={data.banner.description}/></span>
								{/*endregion*/}
							</div>
							{/*endregion*/}

							{/*region Contact-us link*/}
							<Link
									scroll={false}
									href={'/contact'}
									className={styles.link}
							>
								<div className={styles.link_text}>{data.banner.button_text}</div>
							</Link>
							{/*endregion*/}
						</div>
							{/*endregion*/}

						{/*region Right*/}
						<div className={styles.right}>
							{smallScreenSize ? <Image className={styles.image_mobile} src={"/images/image_background_hero_mobile.png"} alt={'Locaux Income'} fill={true} quality={100}/>
									: <Image className={styles.image} src={"/images/image_background_hero.png"} alt={'Locaux Income'} fill={true} quality={100}/>
							}
						</div>
						{/*endregion*/}

						{/*<div className={styles.left}>*/}
						{/*	/!*region Top*!/*/}
						{/*	<div className={styles.top}>*/}
						{/*		/!*region Text*!/*/}
						{/*		<div className={styles.text}>*/}
						{/*			/!*region title*!/*/}
						{/*			<h1 className={styles.title}><Markdown value={data.banner.title}/></h1>*/}
						{/*			/!*endregion*!/*/}

						{/*			/!*region Description*!/*/}
						{/*			<span className={styles.description}><Markdown value={data.banner.description}/></span>*/}
						{/*			/!*endregion*!/*/}
						{/*		</div>*/}
						{/*		/!*endregion*!/*/}

								{/*/!*region Contact-us link*!/*/}
								{/*<Link*/}
								{/*	scroll={false}*/}
								{/*	href={'/contact'}*/}
								{/*	className={styles.link}*/}
								{/*>{data.banner.button_text}</Link>*/}
								{/*/!*endregion*!/*/}
						{/*	</div>*/}
						{/*	/!*endregion*!/*/}

						{/*	/!*region Bottom*!/*/}
						{/*	<div className={styles.bottom}>*/}
						{/*		/!*TODO: Maybe put in Backoffice*!/*/}
						{/*		<div className={styles.title}>*/}
						{/*			Suivez-nous sur les réseaux*/}
						{/*		</div>*/}

						{/*		<div className={styles.list}>*/}
						{/*			{data.banner.reseaux_sociaux.data.map((social) => <SocialNetwork_WithLine*/}
						{/*				key={social.id}*/}
						{/*				href={social.attributes.link}*/}
						{/*				className={styles.icon}*/}
						{/*				socialName={social.attributes.name[0].toUpperCase()*/}
						{/*				            + social.attributes.name.substring(1)}*/}
						{/*			>*/}
						{/*				{typeof window*/}
						{/*				 !== 'undefined'*/}
						{/*				 && <FontAwesomeIcon*/}
						{/*					 icon={[*/}
						{/*						 'fab',*/}
						{/*						 social.attributes.name,*/}
						{/*					 ]}*/}
						{/*				 />}*/}
						{/*			</SocialNetwork_WithLine>)}*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*	/!*endregion*!/*/}
						{/*</div>*/}
						{/*endregion*/}

						{/*region Right*/}
						{/*<div className={styles.right}>*/}
						{/*	<Banner_Image_Line_SVG className={styles.line}/>*/}
						{/*	<Z_Strapi_Image*/}
						{/*		image={data.banner.image}*/}
						{/*		alt={'Locaux Income'}*/}
						{/*		priority*/}
						{/*	/>*/}
						{/*</div>*/}
						{/*endregion*/}
					</div>
					{/*endregion*/}

					{/*region Notre rôle*/}
					<div className={styles.our_role}>
						<h2 className={styles.title}>{data.section_our_role.title}</h2>

						<div className={styles.list}>
							{data.section_our_role.list.map((role, index) => <OurRoleElement
								key={`role_${index}`}
								number={role.number}
								title={role.title}
								content={role.description}
							/>)}
						</div>
					</div>
					{/*endregion*/}

					{/*region Domaines*/}
					<div className={styles.areas_of_competences}>
						<h2 className={styles.title}>
							<span><Markdown value={data.section_domaines.title}/></span>
						</h2>

						<div className={styles.list}>
							{data.section_domaines.list.map((element, index) =>

								                                <AreaOfCompetencesElement
									                                key={`area_of_competences_${index}`}
									                                number={element.number}
									                                category={element.category}
									                                title={element.title}
									                                content={element.description}
									                                icon={element.icon}
								                                />)}
						</div>
					</div>
					{/*endregion*/}

					{/*region Income birth*/}
					{/*TODO: Add in backoffice*/}
					<div
						className={cn(
							styles.income_birth_container,
							'income_birth_container',
						)}
						id={'income-birth'}
					>
						<div
							className={cn(
								styles.income_birth_content,
								'income_birth_content',
							)}
						>
							<h2
								className={cn(
									styles.title,
									'title',
								)}
							>La naissance d&apos;income<br/> <em>découvrez notre histoire</em></h2>

							<div className={styles.end}>
								{/* Add parallax */}
								<div
									className={cn(
										styles.floating_elements,
										'floating_elements',
									)}
									ref={parallaxSceneRef}
								>
									<div
										data-depth={.3}
									>
										<Image
											src={BottomIMG}
											alt={'icon'}
											className={styles.bottom}
										/>
									</div>
									<div
										data-depth={.5}
									>
										<Image
											src={BottomLeftIMG}
											alt={'icon'}
											className={styles.bottom_left}
										/>
									</div>
									<div
										data-depth={.25}
									>
										<Image
											src={RightIMG}
											alt={'icon'}
											className={styles.right}
										/>
									</div>
									<div
										data-depth={.2}
									>
										<Image
											src={TopLeftIMG}
											alt={'icon'}
											className={styles.top_left}
										/>
									</div>
									<div
										data-depth={.3}
									>
										<Image
											src={TopRightIMG}
											alt={'icon'}
											className={styles.top_right}
										/>
									</div>
								</div>

								<span
									className={cn(
										styles.discover_us,
										'discover_us',
									)}
								>Découvrez-nous</span>

								<Income_Birth_Line_SVG
									className={styles.line}
									id={'income_birth_line'}
								/>

								<div
									className={cn(
										styles.center,
										'center',
									)}
								>
									{smallScreenSize
									 ? <>
										 <div
											 className={cn(
												 styles.cegex_and_qubbe,
												 'cegex_and_qubbe',
											 )}
										 >
											 <Logo_Birth_Mobile_SVG/>
										 </div>

										 <div
											 className={cn(
												 styles.experience,
												 'experience',
											 )}
										 >
											 <span>pour vous fournir<br/>un service </span>
										 </div>

										 <div
											 className={cn(
												 styles.twenty_twenty_two,
												 'twenty_twenty_two',
											 )}
										 ><span> C&apos;est <em>21 ans</em><br/>d&apos;expérience</span></div>

										 <div
											 className={cn(
												 styles.service,
												 'service',
											 )}
										 >
											 <span><em>collaboratif,<br/>complet et<br/>performant</em></span>
										 </div>

										 <Link
											 scroll={false}
											 href={'/nos-metiers#conseil'}
											 className={cn(
												 styles.conseil,
												 'conseil',
											 )}
										 >
											 Conseil
										 </Link>
										 <Link
											 scroll={false}
											 href={'/nos-metiers#audit'}
											 className={cn(
												 styles.audit,
												 'audit',
											 )}
										 >
											 Audit
										 </Link>
										 <Link
											 scroll={false}
											 href={'/nos-metiers#social'}
											 className={cn(
												 styles.social,
												 'social',
											 )}
										 >
											 Social
										 </Link>

										 <div
											 className={cn(
												 styles.links,
												 'links',
											 )}
										 >
											 <Link
												 scroll={false}
												 href={'/qui-sommes-nous#notre-histoire'}
												 aria-label={`Découvrez notre histoire`}
												 className={cn(
													 styles.text,
													 'text',
												 )}
											 >Découvrez notre histoire
											 </Link>

											 <Link
												 scroll={false}
												 href={'/qui-sommes-nous#notre-histoire'}
												 aria-label={`Découvrez notre histoire`}
												 className={cn(
													 styles.icon_container,
													 'icon_container',
												 )}
											 >
												 <Arrow_Right_SVG
													 className={cn(
														 styles.icon,
														 'icon',
													 )}
												 />
											 </Link>
										 </div>
									 </>
									 : <>
										 <div className={styles.line_1}>
											 <div
												 className={cn(
													 styles.cegex_and_qubbe,
													 'cegex_and_qubbe',
												 )}
											 >
												 <Logo_Birth_SVG/>
											 </div>

											 <div
												 className={cn(
													 styles.experience,
													 'experience',
												 )}
											 >
												 <span>pour vous fournir<br/>un service </span>
											 </div>
										 </div>

										 <div
											 className={cn(
												 styles.line_2,
												 'line_2',
											 )}
										 >
											 <div
												 className={cn(
													 styles.twenty_twenty_two,
													 'twenty_twenty_two',
												 )}
											 ><span>C&apos;est <em>21 ans</em><br/>d&apos;expérience</span></div>

											 <div
												 className={cn(
													 styles.service,
													 'service',
												 )}
											 >
												 <span><em>collaboratif, complet<br/>et performant</em></span>
											 </div>
										 </div>

										 <div
											 className={cn(
												 styles.list,
												 'list',
											 )}
										 >
											 <Link
												 scroll={false}
												 href={'/nos-metiers#conseil'}
												 className={cn(
													 styles.conseil,
													 'conseil',
												 )}
											 >
												 Conseil
											 </Link>
											 <Link
												 scroll={false}
												 href={'/nos-metiers#audit'}
												 className={cn(
													 styles.audit,
													 'audit',
												 )}
											 >
												 Audit
											 </Link>
											 <Link
												 scroll={false}
												 href={'/nos-metiers#social'}
												 className={cn(
													 styles.social,
													 'social',
												 )}
											 >
												 Social
											 </Link>
										 </div>

										 <div
											 className={cn(
												 styles.links,
												 'links',
											 )}
										 >
											 <Link
												 scroll={false}
												 href={'/qui-sommes-nous#notre-histoire'}
												 aria-label={`Découvrez notre histoire`}
												 className={cn(
													 styles.text,
													 'text',
												 )}
											 >Découvrez notre histoire
											 </Link>

											 <Link
												 scroll={false}
												 href={'/qui-sommes-nous#notre-histoire'}
												 aria-label={`Découvrez notre histoire`}
												 className={cn(
													 styles.icon_container,
													 'icon_container',
												 )}
											 >
												 <Arrow_Right_SVG
													 className={cn(
														 styles.icon,
														 'icon',
													 )}
												 />
											 </Link>
										 </div>
									 </>}
								</div>
							</div>
						</div>
					</div>
					{/*endregion*/}

					{/*region Competences*/}
					<div className={styles.competences_container}>
						<div className={styles.competences_content}>
							<div className={styles.image_left}>
								<Z_Strapi_Image image={data.section_valeurs.image}/>
							</div>

							<div className={styles.right}>
								<h2 className={styles.title}><Markdown value={data.section_valeurs.title}/></h2>
								<span className={styles.description}><Markdown value={data.section_valeurs.description}/></span>

								<div className={styles.list}>
									{data.section_valeurs.list.map((valeur, index) => <span
										key={`valeur_${index}`}
										className={styles.item}
									>{valeur.title}</span>)}
								</div>
							</div>
						</div>
					</div>
					{/*endregion*/}

					{/*region Team*/}
					<div
						className={cn(
							styles.team,
							'team',
						)}
					>
						<div className={styles.left}>
							<h2 className={styles.title}>{data.section_team.title}</h2>
							<span className={styles.description}>{data.section_team.description}</span>
						</div>

						<Link
							scroll={false}
							className={styles.link}
							href={'/qui-sommes-nous'}
						>{data.section_team.button_text}</Link>

						<Swiper
							className={styles.list}

							spaceBetween={20}

							freeMode={{
								enabled:               true,
								minimumVelocity:       .02,
								momentum:              true,
								momentumBounce:        true,
								momentumBounceRatio:   .25,
								momentumRatio:         .5,
								momentumVelocityRatio: 1,
								sticky:                true,
							}}

							breakpoints={{
								1:    {
									slidesPerView: 1.05,
								},
								1025: {
									slidesPerView: 4.3,
								},
							}}

							modules={[Navigation]}
							navigation={{
								nextEl:        '.team .next_arrow',
								prevEl:        '.team .prev_arrow',
								disabledClass: 'disabled',
								hiddenClass:   'hidden',
							}}

							ref={sliderRef}

							//region Handlers
							onActiveIndexChange={() => {
								setIsBeginning(sliderRef.current.swiper.isBeginning)
								setIsEnd(sliderRef.current.swiper.isEnd)
							}}
							//endregion
						>
							{randomTeamMembers.map(team_member => <SwiperSlide key={team_member.id}>
								<TeamMember
									team_member={team_member.attributes}
								/>
							</SwiperSlide>)}

							<div
								className={cn(
									styles.prev_arrow,
									'prev_arrow',
									{[styles.disabled]: isBeginning},
								)}
								onClick={handlePrev}
							>
								<Swiper_Arrow_SVG/>
							</div>

							<div
								className={cn(
									styles.next_arrow,
									'next_arrow',
									{[styles.disabled]: isEnd},
								)}
								onClick={handleNext}
							>
								<Swiper_Arrow_SVG/>
							</div>
						</Swiper>
					</div>
					{/*endregion*/}

					{/*region Why chose us*/}
					<div className={styles.why_chose_us}>
						<div className={styles.left}>
							<h2
								className={styles.title}
								dangerouslySetInnerHTML={{__html: data.section_why_chose_us.title}}
							></h2>

							<span className={styles.description}><Markdown value={data.section_why_chose_us.description}/></span>

							<LinkWithArrow
								link={'/nos-metiers'}
								text={data.section_why_chose_us.link}
							/>

							<LinkWithArrow
								link={'/notre-ecosysteme'}
								text={'En savoir plus sur nos outils de pilotage pour votre entreprise'}
							/>
						</div>

						<div className={styles.right}>
							{data.section_why_chose_us.list.map((elt, index) => <WhyChoseUsItem
								key={`why_chose_us${index}`}
								question={elt.title}
								answer={elt.description}

								id={index}
								opened={selectedWhyChoseUs
								        === index}
								setOpened={changeSelectedWhyChoseUs}
							/>)}
						</div>
					</div>
					{/*endregion*/}

					{/*TODO: API LinkedIn*/}
					{/*region Our actuality*/}
					{/*<div className={styles.our_actuality}>
					 <h2 className={styles.title}>Toute notre actualité</h2>

					 <Link
					 scroll={false}
					 href={'#'}
					 className={styles.link}
					 >
					 <span className={styles.text}>Toute notre actualité</span>
					 <FaLinkedin/>
					 </Link>

					 <Swiper
					 className={styles.list}
					 spaceBetween={20}

					 breakpoints={{
					 1:    {
					 slidesPerView: 1.1,
					 },
					 1025: {
					 slidesPerView: 4.3,
					 },
					 }}
					 >
					 <SwiperSlide>
					 <LinkedinPost
					 image={CompetencesRightImage}
					 title={'Chiffre du jour'}
					 description={'La France est au 11e rang mondial des pays les plus innovants au classement du Global innovation Index 2021 (12ème en 2020)'}
					 />
					 </SwiperSlide>
					 <SwiperSlide>
					 <LinkedinPost
					 image={CompetencesRightImage}
					 title={'Chiffre du jour'}
					 description={'La France est au 11e rang mondial des pays les plus innovants au classement du Global innovation Index 2021 (12ème en 2020)'}
					 />
					 </SwiperSlide>
					 <SwiperSlide>
					 <LinkedinPost
					 image={CompetencesRightImage}
					 title={'Chiffre du jour'}
					 description={'La France est au 11e rang mondial des pays les plus innovants au classement du Global innovation Index 2021 (12ème en 2020)'}
					 />
					 </SwiperSlide>
					 <SwiperSlide>
					 <LinkedinPost
					 image={CompetencesRightImage}
					 title={'Chiffre du jour'}
					 description={'La France est au 11e rang mondial des pays les plus innovants au classement du Global innovation Index 2021 (12ème en 2020)'}
					 />
					 </SwiperSlide>
					 <SwiperSlide>
					 <LinkedinPost
					 image={CompetencesRightImage}
					 title={'Chiffre du jour'}
					 description={'La France est au 11e rang mondial des pays les plus innovants au classement du Global innovation Index 2021 (12ème en 2020)'}
					 />
					 </SwiperSlide>
					 <SwiperSlide>
					 <LinkedinPost
					 image={CompetencesRightImage}
					 title={'Chiffre du jour'}
					 description={'La France est au 11e rang mondial des pays les plus innovants au classement du Global innovation Index 2021 (12ème en 2020)'}
					 />
					 </SwiperSlide>
					 <SwiperSlide>
					 <LinkedinPost
					 image={CompetencesRightImage}
					 title={'Chiffre du jour'}
					 description={'La France est au 11e rang mondial des pays les plus innovants au classement du Global innovation Index 2021 (12ème en 2020)'}
					 />
					 </SwiperSlide>
					 <SwiperSlide>
					 <LinkedinPost
					 image={CompetencesRightImage}
					 title={'Chiffre du jour'}
					 description={'La France est au 11e rang mondial des pays les plus innovants au classement du Global innovation Index 2021 (12ème en 2020)'}
					 />
					 </SwiperSlide>
					 </Swiper>
					 </div>*/}
					{/*endregion*/}

					{/*region Newsletter*/}
					<div className={styles.newsletter}>
						<div className={styles.left}>
							<Image
								src={NewsletterImage}
								alt={'Image habillant la section newsletter'}
								fill={true}
							/>
						</div>

						<NewsletterForm
							id={'main_page_section_newsletter'}
							className={styles.right}
							subdescription={'En vous inscrivant à la newsletter, vous acceptez de recevoir des nouvelles de notre part.'}
						/>
					</div>
					{/*endregion*/}

					{/*region Contact us*/}
					<ContactUs_Section/>
					{/*endregion*/}

					{/*region Footer*/}
					<Footer data={data.footer_data}/>
					{/*endregion*/}
				</div>
			</div>
		</>
	)
}

const LinkedinPost = ({
	                      image,
	                      title,
	                      description,
                      }) => {
	return (
		<div className={styles.linkedin_post}>
			<div className={styles.image_container}>
				<Z_Strapi_Image image={image}/>
				<Image
					className={styles.image}
					src={image}
					alt={'Image correspondant à l\'article LinkedIn'}
					fill={true}
				/>
			</div>

			<div className={styles.content}>
				<span className={styles.title}>{title}</span>

				<span className={styles.description}>{description}</span>
			</div>
		</div>
	)
}

const OurRoleElement = ({
	                        number,
	                        title,
	                        content,
                        }) => {
	return (
		<div className={styles.our_role_element}>
			<div className={styles.number}>
				<span className={styles.text}>{number}</span>
			</div>

			<span className={styles.title}>{title}</span>

			<span className={styles.content}>{content}</span>
		</div>
	)
}

const AreaOfCompetencesElement = ({
	                                  number,
	                                  category,
	                                  title,
	                                  content,
	                                  icon,
                                  }) => {

	const areasOfCompetencesElementRef = useRef()

	useEffect(
		() => {
			// region Pin - Scroll Trigger
			let pinScrollTrigger
			if (number
			    < 3) {
				pinScrollTrigger = ScrollTrigger.create({
					                                        trigger: areasOfCompetencesElementRef.current,
					                                        start:   'top top',
					                                        end:     'bottom top',

					                                        /*region Change border radius value*/
					                                        onEnter:     self => {
						                                        //region Change border top radius
						                                        gsap.to(
							                                        areasOfCompetencesElementRef.current,
							                                        {
								                                        borderTopLeftRadius:  0,
								                                        borderTopRightRadius: 0,
							                                        },
						                                        )
						                                        //endregion
					                                        },
					                                        onLeaveBack: self => {
						                                        //region Reset border top radius
						                                        gsap.to(
							                                        areasOfCompetencesElementRef.current,
							                                        {
								                                        borderTopLeftRadius:  16
								                                                              * (
									                                                              window.innerWidth
									                                                              > 1024
									                                                              ? 6
									                                                              : 2
								                                                              ),
								                                        borderTopRightRadius: 16
								                                                              * (
									                                                              window.innerWidth
									                                                              > 1024
									                                                              ? 6
									                                                              : 2
								                                                              ),
							                                        },
						                                        )
						                                        //endregion
					                                        },

					                                        pin:        true,
					                                        pinSpacing: false,

					                                        // Anticipate pin
					                                        anticipatePin: 1,
				                                        })
			}
			//endregion

			return () => {
				pinScrollTrigger?.kill()
			}
		},
		[number],
	)

	return (
		<div
			className={styles.areas_of_competences_element}
			ref={areasOfCompetencesElementRef}
		>
			<div className={styles.content}>
				<div className={styles.top}>
					<div className={styles.category}>
						<div className={styles.number}>
		<span className={styles.value}>
	{number}
		</span>
						</div>

						<span className={styles.name}>{category}</span>
					</div>

					<div className={styles.content}>
						<h3 className={styles.title}>{title}</h3>

						<div
							className={styles.description}
						>
							<Markdown
								value={content}
							/>
						</div>
					</div>
				</div>

				<div className={styles.icon}>
					<Z_Strapi_Image image={icon}/>
				</div>
			</div>
		</div>
	)
}


export const getStaticProps = async () => {
	axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.API_TOKEN}`

	const query = qs.stringify(
		{
			fields:   [
				/* SEO */
				'seo_title',
				'seo_description',
			],
			populate: {
				banner:               {
					fields:   [
						'title',
						'description',
						'button_text',
					],
					populate: {
						image:           {
							fields: [
								'url',
								'alternativeText',
								'width',
								'height',
								'formats',
							],
						},
						reseaux_sociaux: {
							fields: [
								'name',
								'link',
							],
						},
					},
				},
				section_our_role:     {
					fields:   [
						'title',
					],
					populate: {
						list: {
							fields: [
								'title',
								'number',
								'description',
							],
						},
					},
				},
				section_domaines:     {
					fields:   [
						'title',
					],
					populate: {
						list: {
							fields:   [
								'number',
								'category',
								'title',
								'description',
							],
							populate: {
								icon: {
									fields: [
										'url',
										'alternativeText',
										'width',
										'height',
										'formats',
									],
								},
							},
						},
					},
				},
				section_valeurs:      {
					fields:   [
						'title',
						'description',
					],
					populate: {
						list:  {
							fields: ['title'],
						},
						image: {
							fields: [
								'url',
								'alternativeText',
								'width',
								'height',
								'formats',
							],
						},
					},
				},
				section_team:         {
					fiedls: [
						'title',
						'description',
						'button_text',
					],
				},
				section_why_chose_us: {
					fiedls:   [
						'title',
						'description',
						'link',
					],
					populate: {
						list: {
							fields: [
								'title',
								'description',
							],
						},
					},
				},
			},
		},
		{encodeValuesOnly: true},
	)

	let data = null

	await axios.get(`${process.env.NEXT_PUBLIC_SITE_API_LINK}/api/page-accueil?${query}`)
	           .then(res => {
		           data = res.data.data.attributes
	           })

	// Get team members
	const teamMembersQuery = qs.stringify(
		{
			pagination: {
				page:     1,
				pageSize: 99,
			},
			fields:     [
				'id',
				'firstname',
				'lastname',
				'job',
				'phone',
				'mail',
				'function',
			],
			populate:   {
				image: {
					fields: [
						'url',
						'alternativeText',
						'width',
						'height',
						'formats',
					],
				},
			},
			sort:       [
				'rank',
				'firstname',
				'lastname',
			],
		},
		{encodeValuesOnly: true},
	)

	await axios.get(`${process.env.NEXT_PUBLIC_SITE_API_LINK}/api/team-members?${teamMembersQuery}`)
	           .then(res => {
		           data.team_members = res.data.data
	           })

	data.footer_data = await getFooterData()

	return {
		props: {
			data: data,
		},

		revalidate: parseInt(process.env.REVALIDATE_DELAY)
		            ?? 10,
	}
}

export default MainPage
