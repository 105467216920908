const Arrow_Right_SVG = ({
	                         middleDotClassName,
	                         ...props
                         }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='37.112'
			height='27.521'
			viewBox='0 0 37.112 27.521'
			stroke='#d36f3b'

			{...props}
		>
			<g
				id='Group_101'
				data-name='Group 101'
				transform='translate(50.725 -6.806) rotate(90)'
			>
				<line
					id='Line_9'
					data-name='Line 9'
					y1='35.168'
					transform='translate(20.567 15.557)'
					fill='none'
					strokeMiterlimit='10'
					strokeWidth='2.75'
				/>
				<path
					id='Path_141'
					data-name='Path 141'
					d='M7.778,28.345,20.567,15.557,33.355,28.345'
					transform='translate(0 0)'
					fill='none'
					strokeMiterlimit='10'
					strokeWidth='2.75'
				/>
			</g>
		</svg>
	)
}

export default Arrow_Right_SVG
